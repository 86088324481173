import React, { useRef, useState } from "react"
import emailjs from "@emailjs/browser"
import { ContactFormTitle } from "@/data"
import Toast from "react-bootstrap/Toast"
import ToastContainer from "react-bootstrap/ToastContainer"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

const ContactForm = () => {
  const form = useRef()
  const [show, setShow] = useState(false)
  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        "service_f2f2fcg",
        "template_ks5rxu8",
        form.current,
        "66P1bKO_0X_rdpPl-"
      )
      .then(
        result => {
          setShow(true)
        },
        error => {
        }
      )

    e.target.reset()
  }
  const { title, description } = ContactFormTitle
  return (
    <section className="commonSection ContactPage">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            {/* <h4 className="sub_title">{subTitle}</h4> */}
            <h2 className="sec_title">{title}</h2>
            <p className="sec_desc">{description}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 offset-lg-2 col-sm-12 col-md-10 offset-md-1">
            <GoogleReCaptchaProvider reCaptchaKey="6Lefr6IhAAAAAN-WL_GZDEh2bd_QH-9uvECKEkO1">
              <form
                ref={form}
                onSubmit={sendEmail}
                className="contactFrom"
                id="contactForm"
              >
                {/* <form
              action="#"
              method="post"
              className="contactFrom"
              id="contactForm"
            > */}
                <div className="row">
                  <div className="col-lg-6 col-sm-6">
                    <input
                      className="input-form"
                      type="text"
                      name="first_name"
                      id="f_name"
                      placeholder="First Name"
                      required
                    />
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <input
                      className="input-form"
                      type="text"
                      name="last_name"
                      id="l_name"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <input
                      className="input-form"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      required
                    />
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <input
                      className="input-form"
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="Phone Number"
                      required
                    />
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <textarea
                      className="input-form"
                      name="message"
                      id="message"
                      placeholder="Write Message"
                      required
                    ></textarea>
                  </div>
                </div>
                <button
                  className="common_btn red_bg"
                  type="submit"
                  value="Send"
                  id="con_submit"
                >
                  <span>Send Message</span>
                </button>
              </form>
            </GoogleReCaptchaProvider>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-end" className="p-3">
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Body>
            <h1 className="toast-hd">
              {" "}
              <i className="fa fa-thumbs-up"></i>{" "}
            </h1>
            <h4 className="toast-hd">
              Thank you! We will get back to you shortly
            </h4>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </section>
  )
}

export default ContactForm
